<template>
    <main-layout
        itemMenuActive="39"
        tituloMenu="Consulta Diario OnBoarding Digital"
    >
      <div class="container">
        <br/>
        <div>
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-12">
              <div class="card">
                <div class="card-content">
                  <div style="display: flex; justify-content: space-between; align-items: center">
                    <p class="title is-4" style="margin-bottom: auto; margin-top: auto">Consulta Diario OnBoarding Digital</p>
                    <div>
                      <b-button v-on:click="exportExcel" :disabled="dataTable.length === 0">Excel</b-button>
                      <b-button v-on:click="exportCSV" :disabled="dataTable.length === 0">CSV</b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="columns is-mobile is-multiline is-centered">
          <div class="column is-6" v-if="seriesExitosasFalliddas.length > 0">
            <div class="card">
              <div class="card-content">
                <span>Transacciones Exitosas vs Fallidas</span>
                <div v-if="seriesExitosasFalliddas.length > 0">
                  <apexchart
                      :height="250"
                      type="pie"
                      :options="chartOptionsExitosasFalliddas"
                      :series="seriesExitosasFalliddas"
                  ></apexchart>
                </div>
                <div v-else>
                  <b-message type="is-info"> Sin datos para procesar.</b-message>
                </div>
              </div>
            </div>
          </div>
          <div class="column is-6" v-if="seriesAcceptRiskyPending.length > 0">
            <div class="card">
              <div class="card-content">
                <span>Transacciones por tipo de estado</span>
                <div v-if="seriesAcceptRiskyPending.length > 0">
                  <apexchart
                      :height="250"
                      type="pie"
                      :options="chartOptionsAcceptRiskyPending"
                      :series="seriesAcceptRiskyPending"
                  ></apexchart>
                </div>
                <div v-else>
                  <b-message type="is-info"> Sin datos para procesar.</b-message>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="dataTable.length > 0">
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-12">
              <div class="card">
                <div class="card-content" style="display: inline-block;">
                  <TableCard :columns="columnsDailyOnBoardingTable"
                             :have-filter="false" :info="dataTable"
                             :tableConfig="tableConfig" detailKey="counter"></TableCard>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-mobile is-multiline is-centered">
            <div class="column is-3">
              <b-tag type="is-primary" class="control has-text-centered" size="is-medium">Transacciones Exitosas:
                {{ total_exitosas }}
              </b-tag>
            </div>
            <div class="column is-3">
              <b-tag type="is-warning" class="control" size="is-medium">Transacciones Fallidas: {{
                  total_fallidas
                }}
              </b-tag>
            </div>
          </div>
        </div>
        <div v-else>
          <b-message type="is-info"> Sin datos para procesar.</b-message>
        </div>
      </div>
      <b-loading
          :is-full-page="true"
          v-model="isLoading"
          :can-cancel="true"
      ></b-loading>
    </main-layout>
  </template>
  
  <script>
  import MainLayout from "@/components/Layout/MainLayout";
  import {mapMutations, mapActions, mapState, mapGetters} from "vuex";
  import apexchart from "vue-apexcharts";
  import {COLUMNS_DAILY_ONBOARDING_TABLE} from "../../assets/config/constants/TableConstants";
  import TableCard from "../../components/cards/TableCard";
  import {formatData} from "../../assets/config/utilities";
  import {VU_SOP_ESTADOS} from "../../assets/config/constants/GeneralConstants";
  import {saveLog} from "../../services/exportLogs";
  
  let colores = [
    "#BFDB38",
    "#FC7300",
    "#FCE22A",
    "#0096FF",
    "#FF004D",
    "#A31ACB",
    "#FF0000",
  ];
  
  export default {
    name: "ReporteDiarioOnBoardingFinan",
    components: {
      MainLayout,
      apexchart,
      TableCard
    },
    data() {
      return {
        maxDate: new Date(),
        total_exitosas: 0,
        total_fallidas: 0,
        isLoading: false,
        dataTable: [],
        counter: 0,
        seriesWebMobil: [],
        chartOptionsWebMovil: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: ["Web", "Mobil"],
          dataLabels: {
            dropShadow: {
              enabled: false,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        seriesExitosasFalliddas: [],
        chartOptionsExitosasFalliddas: {
          chart: {
            width: 380,
            type: "pie",
          },
          labels: ["Exitosas", "Fallidas"],
          dataLabels: {
            dropShadow: {
              enabled: false,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        seriesAcceptRiskyPending: [],
        chartOptionsAcceptRiskyPending: {
          colors: colores,
          chart: {
            width: 380,
            type: "pie",
          },
          labels: [],
          dataLabels: {
            dropShadow: {
              enabled: false,
            },
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        locale: "en-CA",
        tableConfig: {
          isPaginated: true,
          isPaginationRounded: true,
          perPage: 10,
          backendSorting: true,
          isDetailed: false
        },
        columnsDailyOnBoardingTable: COLUMNS_DAILY_ONBOARDING_TABLE,
      };
    },
    computed: {
      ...mapState(["_HOST_SERVE", "_PERFIL_MODULO_"]),
    },
    mounted() {
      this.obtenerRangoFecha();
      this.$emit("tituloTransaccion", "VU OnBoarding");
      this.nombreTituloMenu("Secure Onboarding Process");
    },
    methods: {
      obtenerRangoFecha() {
        const options = {
          method: "get",
          uri: "boards/getRangeDate",
        };
        this._axios(options)
          .then((response) => {
            this.maxDate = new Date(response.data[1]);
          })
          .catch((error) => {
            this.isLoadingTable = false;
            console.log(error);
          })
          .finally(() => this.cancelarLoading());
        this.handleSubmit();
      },
      handleSubmit() {
        console.log(this.maxDate);
        if (this._PERFIL_MODULO_.onboarding) {
          this.isLoading = true;
          this.dataTable = [];
          this.counter = 0;
          const options = {
            method: "post",
            uri: "finan/SOP",
            data: {
              FechaInicial: this.maxDate,
              FechaFinal: this.maxDate,
              tipo: "day",
            },
          };
          console.log(this.maxDate);
          this.seriesWebMobil.length = 0;
          this.seriesExitosasFalliddas.length = 0;
          this.seriesAcceptRiskyPending.length = 0;
  
          this._axios(options)
              .then((response) => {
                response.data.datos.forEach((item) => {
                  this.counter++;
                  item.counter = this.counter;
                  this.dataTable.push(item);
                });
                this.dataTable = formatData(this.dataTable);
                this.total_exitosas = response.data.cant_exitosas;
                this.total_fallidas = response.data.cant_fallidas;
  
                if (
                    response.data.cant_exitosas > 0 ||
                    response.data.cant_fallidas > 0
                ) {
                  this.seriesExitosasFalliddas.push(response.data.cant_exitosas);
                  this.seriesExitosasFalliddas.push(response.data.cant_fallidas);
                }
  
                response.data.estados.forEach((item) => {
                  console.log(item);
                  this.seriesAcceptRiskyPending.push(item.cantidad);
                  this.chartOptionsAcceptRiskyPending.labels.push(
                  VU_SOP_ESTADOS.get(item.estado)
              );
                })
  
              })
              .catch((error) => {
                this.isLoadingTable = false;
                console.log(error);
              })
              .finally(() => this.cancelarLoading());
        } else {
          this.$router.push("login");
        }
      },
      exportExcel() {
        this.exportFile("exportExcelSOPFinan");
        this.saveExportEvent("excel")
      },
      exportCSV() {
        this.exportFile("exportCSVSOPFinan");
          this.saveExportEvent("csv")
      },
      exportFile(type) {
        if (this.fechaInicial !== "") {
          const fecha = this.convertFecha(this.maxDate);
          const routeData = `${this._HOST_SERVE}/finan/${type}/${fecha}/${fecha}`;
          window.open(routeData, "_blank");
        }
      },
      convertFecha: function (fecha) {
        let date = new Date(fecha).toISOString().slice(0, 10);
        return date;
      },
      cancelarLoading() {
        setTimeout(() => {
          this.isLoading = false;
        }, 1500);
      },
        saveExportEvent(type) {
            saveLog({
                "user": this.getUsuario(),
                "sectionId": 2,
                "reportId": 2,
                "format": type
            })
        },
      ...mapMutations(["nombreTituloMenu"]),
      ...mapActions(["_axios"]),
        ...mapGetters(["getUsuario"])
    },
  };
  </script>
  <style>
  
  .button.is-primary.is-outlined {
    background-color: transparent;
    border-color: #00522a;
    color: #00522a;
  }
  
  .button.is-primary.is-outlined:hover, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined.is-focused {
    background-color: rgba(0, 82, 42, .8);
    border-color: #00522a;
    color: #fff;
  }
  
  .has-text-primary {
    color: #00522A !important;
  }
  
  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-today {
    border: solid 1px rgba(0, 82, 42, 1);
  }
  
  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-first-selected {
    background-color: #00522A;
    color: #fff;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  
  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-within-selected {
    background-color: rgba(0, 82, 42, 0.5);
    border-radius: 0;
  }
  
  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected {
    background-color: #00522A;
    color: #fff;
  }
  
  .datepicker .datepicker-table .datepicker-body .datepicker-cell.is-selected.is-last-selected {
    background-color: #00522A;
    color: #fff;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
  
  .select select:focus, .taginput .taginput-container.is-focusable:focus, .textarea:focus, .input:focus, .select select.is-focused, .taginput .is-focused.taginput-container.is-focusable, .is-focused.textarea, .is-focused.input, .select select:active, .taginput .taginput-container.is-focusable:active, .textarea:active, .input:active, .select select.is-active, .taginput .is-active.taginput-container.is-focusable, .is-active.textarea, .is-active.input {
    border-color: #00522A;
    -webkit-box-shadow: 0 0 0 0.125em rgba(0, 82, 42, 0.5);
    box-shadow: 0 0 0 0.125em rgba(0, 82, 42, 0.5);
  }
  
  .datepicker .dropdown .input[readonly]:focus, .datepicker .dropdown .input[readonly].is-focused, .datepicker .dropdown .input[readonly]:active, .datepicker .dropdown .input[readonly].is-active, .datepicker .dropdown-trigger .input[readonly]:focus, .datepicker .dropdown-trigger .input[readonly].is-focused, .datepicker .dropdown-trigger .input[readonly]:active, .datepicker .dropdown-trigger .input[readonly].is-active {
    -webkit-box-shadow: none;
    box-shadow: none;
  }
  
  .select select:focus, .taginput .taginput-container.is-focusable:focus, .textarea:focus, .input:focus, .select select.is-focused, .taginput .is-focused.taginput-container.is-focusable, .is-focused.textarea, .is-focused.input, .select select:active, .taginput .taginput-container.is-focusable:active, .textarea:active, .input:active, .select select.is-active, .taginput .is-active.taginput-container.is-focusable, .is-active.textarea, .is-active.input {
    border-color: #00522A;
    box-shadow: none;
  }
  
  .b-table .table .chevron-cell > a {
    color: #00522A !important;
  }
  
  .card-conten {
    display: inline-block;
  }
  
  tbody {
    font-size: 12px !important;
  }
  
  .pagination-link.is-current {
    background-color: #00522A;
    border-color: #31992D;
    color: #fff;
  }
  
  .button.is-primary {
    background-color: #00522A;
    border-color: transparent;
    color: #fff;
  }
  
  .button.is-primary:active, .button.is-primary.is-active {
    background-color: rgba(0, 82, 42, .8);
    border-color: transparent;
    color: #fff;
  }
  
  .button.is-primary:hover, .button.is-primary.is-hovered {
    background-color: rgba(0, 82, 42, .8);
    border-color: transparent;
    color: #fff;
  }
  </style>